<template>
	<v-list-item>
		<v-list-item-content>
			<v-list-item-title v-text="template.name" />
		</v-list-item-content>
		<v-list-item-action>
			<v-row>
				<v-col>
					<v-chip
						v-for="document in template.documents"
						:key="`template${template.id}document${document.id}`"
						small
						:ripple="false"
						:color="document.uploaded ? 'success' : 'error'"
						class="mx-1"
						@click="document.uploaded ? callDownloadDocument(document) : callNoDocument()"
						:close="document.uploaded"
					>
						<v-icon left> mdi-cloud-download </v-icon>
						{{ document.name }}
					</v-chip>

					<v-dialog v-model="dialog" persistent max-width="350">
						<template v-slot:activator="{ on }">
							<v-btn icon color="error" v-on="on">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</template>
						<v-card rounded="xl">
							<v-card-title>
								<span class="headline">{{ $t('courses.areYouSure') }}</span>
							</v-card-title>
							<v-card-actions>
								<v-spacer />
								<v-btn color="blue darken-1" text rounded @click="dialog = false"> No </v-btn>
								<v-btn color="error darken-1" text rounded @click="callDeleteTemplate(template)">
									{{ $t('settings.delete') }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-col>
			</v-row>
		</v-list-item-action>
	</v-list-item>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'SettingsDocumentsTemplatesItem',
	props: {
		template: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			dialog: false,
		}
	},
	methods: {
		callDownloadDocument(document) {
			this.loading = true
			this.downloadDocument({ documentID: document.id }).then(() => {
				this.loading = false
			})
		},
		callNoDocument() {
			this.noDocument()
		},
		callDeleteTemplate(template) {
			this.loading = true
			this.deleteDocumentsTemplate({ templateID: template.id }).then(() => {
				this.loading = false
			})
		},
		...mapActions('documents', ['downloadDocument', 'noDocument', 'deleteDocumentsTemplate']),
	},
}
</script>
